import $ from 'jquery';  
window.jQuery = $; window.$ = $

import Cookies from 'js-cookie';
import Swiper from 'swiper';
import OpenSeadragon from 'openseadragon';

import 'src/modernizr';
import 'intersection-observer';

const image_observer = new IntersectionObserver((entries, self) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      const $this = $(entry.target),
            src = $this.data('src');
                  
      $this.attr('src', src)      
      $this.removeClass("lzy_img")
      self.unobserve(entry.target)
    }
  })
}, {rootMargin: "0px 0px 600px 0px"});

(function( $ ) {
  
  $.fn.make_method_request = function() {
    return this.filter( "a" ).each(function() {	
      var link = $(this),
        href = link.attr('href'),
        method = link.data('method'),
        target = link.attr('target'),
        csrfToken = $('meta[name=csrf-token]').attr('content'),
        csrfParam = $('meta[name=csrf-param]').attr('content'),
        form = $('<form method="post" action="' + href + '"></form>'),
        metadataInput = '<input name="_method" value="' + method + '" type="hidden" />';

      if (csrfParam !== undefined && csrfToken !== undefined) {
        metadataInput += '<input name="' + csrfParam + '" value="' + csrfToken + '" type="hidden" />';
      }

      if (target) { form.attr('target', target); }

      form.hide().append(metadataInput).appendTo('body');
      form.submit();      
    });
  };
  
  $.fn.exists = function() { 
    return this.length > 0; 
  };
	
  $.fn.make_auto_focus = function() {
    return this.filter( "input[type=text]" ).each(function() {	
      $(this).focus(function() {
          var $this = $(this);

          $this.select();

          window.setTimeout(function() {
              $this.select();
          }, 1);

          // Work around WebKit's little problem
          function mouseUpHandler() {
              // Prevent further mouseup intervention
              $this.off("mouseup", mouseUpHandler);
              return false;
          }

          $this.mouseup(mouseUpHandler);
      });
    });
  };  
  
  $.fn.lzy_load_images = function() {
    return this.each(function() {
      image_observer.observe(this);
    });    
  };
	
}( jQuery ));





$(function(){
   
  $('header button').on('click', function(e){    
    $(this).parent().toggleClass('open');
  })
  
  $('img.lzy_img').lzy_load_images(); 
  
  $('header nav>ul>li>a').on('click', function(e){
    var $this = $(this);

    if ($this.siblings().length > 0 && $('header button.menu:visible').length > 0) {
      $this.toggleClass('open');
      e.preventDefault();  
    } 
  })
  
  setNavigation();
    
  /* Auto select */  
  $('input.auto-select').make_auto_focus();  
  
  
  
  
  /* Cart */
  
  $('body').on('change', '.cart form input[type=text]', function(){
    
    var f = $(this).parents('form:first');
    
    $.ajax({
      url: f.attr('action'),
      data: f.serialize(),
      type: 'PUT',
      beforeSend: function( xhr ) {
        $('.ctrl a', f).attr('disabled', 'disabled');
      }
    })
    .done(function( html ) {
      
      var c = $(html).replaceAll( ".cart" );      
      c.find('input.auto-select').make_auto_focus();      
      var n = c.data('nitems');
      $('#nitems').html(n).attr('data-n', n);
      
    });
    
    
  })
  
  /* Order */
  $('.order .shipping-address button').on('click', function(){    
    var btn = $(this),
        billing_country_select = $('.address:first select');

    // Remove
    if (btn.next().hasClass('address')) {
      btn.next().remove();
      btn.html(btn.data('add'))
      
      billing_country_select.trigger('change:country');

    // Add  
    } else {
      $(btn.data('html')).insertAfter(btn);
      btn.html(btn.data('remove'));
      
      $('.address:last select').val($(billing_country_select).val())
    }
  });
  
  
  // Subscriber  
  $('header .cc button.country').click(function(e){
    helpers.open_in_overlay($(this).data('url'), 'top');
    e.preventDefault();
  });


  
  // Subscriber  
  $('a.newsletter').click(function(e){
    helpers.open_in_overlay($(this).attr('href'), 'bottom');
    e.preventDefault();
  });

  $('body').on('click', '#overlay a.close', function(e){
    helpers.close_overlay();
    e.preventDefault();
  });

  $("body").on('click', '.subscriber input:radio', function(e){
    $(this).parent().find("label.selected").removeClass('selected');
    $(this).next().addClass('selected');
  });
  
  

  $('body').on('submit', '#overlay .subscriber form', function(e){
    var form = $(this);
    
    $.ajax({
      type: "POST",
      url: form.attr('action'),
      data: form.serialize(),
      success: function(html) {
        $(html).replaceAll('#overlay .subscriber');
      }
    });

    form.find('input.ctrl').val('Sending...');
    e.preventDefault();
  });
  
  
  
  
  // Fullscreen
  $(document).on('click', 'img.fullscreen', function(e){

    $('main').data('scroll_top', $(document).scrollTop()).hide();

    $("<div class='fullscreen-bg'></div>").appendTo('body').click(function(e){
			
			if (!$(e.target).hasClass('download')) {
	      $(this).detach();
	      $(document).scrollTop($('main').show().data('scroll_top'));      
	      e.preventDefault();
			}
    }).append('<img src="'+$(this).data('fullscreen')+'" />');    
    $('<a href="/">Close</a>').appendTo("body>.fullscreen-bg").addClass('close-fullscreen');
		
		$('<a title="Download image">Download</a>').appendTo("body>.fullscreen-bg").addClass('download').attr('href', $(this).data('download'));
    
    $(document).scrollTop(0);
    e.preventDefault();
  });
  
  
  // Thumbs 
  $(document).on('click', ".images .thumbs img:not(.current)", function(e){
    var $this = $(this), fig = $('.images figure');
    
    $('img', fig.addClass('loading')).
      attr('src', $this.data('src')).
      data('download', $this.data('download')).
      data('fullscreen', $this.data('fullscreen')).
      on('load', function(){
        $(this).parent().removeClass('loading');
      }
    );
    
    $('img.current', $this.parents('.thumbs')).removeClass('current');
    $this.addClass('current');
    
    $('figcaption', fig).html($this.data('description'));
  });

  // Shop  
  $('body').on('change', '.variants form.variant-selector select', function(e){

    var s = $(this);
    var f = s.parents('form:first');
    //var i = $('select',f).index(s)
    
    $.ajax({
      url: f.attr('action'),
      data: f.serialize(),
      type: 'GET',
      beforeSend: function( xhr ) {
        $('select', f).attr('disabled', 'disabled');
      }
    })
    .done(function( html ) {
      $(html).replaceAll(f.parent());
      
      $("li, input.variant-id", $("#add-to-cart")).remove()
      
      $(".variants form.add-to-cart li").clone().appendTo($("#add-to-cart ul"));
      $(".variants form.add-to-cart input.variant-id").clone().appendTo($("#add-to-cart"));
      
      var price = 0
      $(".variants form.add-to-cart").each(function(){
        price += $(this).data('price')        
      });

      var price_str = $('header button.country').data('currency') + ' ' + (price/100).toFixed(2).replace(/\./g, ",").replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.") + ' incl. VAT';
      if (price > 0) {
        $("article .price").html(price_str)
      } else {
        $("article .price").html('')
      }  
            
      if ($("#add-to-cart input.variant-id").length == $('.variants').length && price > 0) {
        $("#add-to-cart button").prop('disabled', false);
      } else {
        $("#add-to-cart button").attr('disabled', 'disabled')
      }
      
    });
    
  });
  
  
  $('.start-tmpl a.newsletter:first').each(function(){
    var $this = $(this);
    if (Cookies.get('newsletter') == undefined) {
    setTimeout(
      function() {
        Cookies.set('newsletter', 1, { expires: 30 })
        $this.trigger('click');  
        //do something special
      }, 800);
    }
  });
  
  
  var swiper = new Swiper('.swiper-container', {
    slidesPerView: 3,
    paginationClickable: false,
    spaceBetween: 20
  });
  
  
  $('.sc .button-next').on('click', function(e){
    swiper.slideNext();
  });

  $('.sc .button-prev').on('click', function(e){
    swiper.slidePrev();
  });
  
});


$(document).on('submit', '#overlay .countires form', function(e){
  var form = $(this);
  
  $.ajax({
    type: "POST",
    url: form.attr('action'),
    data: form.serialize(),
    format: 'json',
    success: function(html) {
      location.reload();
    }
  });

  form.find('input.ctrl').val('Changing...');
  e.preventDefault();
});


/* Link with method */
$(document).on('click', 'a[data-method]', function(e){
  $(this).make_method_request();
  e.preventDefault();  
})

$(document).on('change', '.address select:visible:last', function(e){
  
  $(this).trigger("change:country")
  
})

$(document).on('change:country', '.address select', function(e){
  $.ajax({
    type: "POST",
    url: $('header .country').data('url')+'.js',
    data: {country: this.value},
    beforeSend: function(xhr) {
      xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
    }
  });  
})



var helpers = {
  

  open_in_overlay: function(url, pos){    
    $.ajax({
      type: "GET",
      url: url,
      success: function(html){           

        $("<div class='bg'></div>").appendTo('body').css({
          width: $(document).width(),
          height: $(document).height(),
          opacity: 0
        }).fadeTo(100, 0.7, function() {
          $(html).appendTo('body').wrap('<div id="overlay" />').parent().hide().css({
            bottom: (pos == 'bottom' ? 20 : 'auto'),
            position: 'fixed',
            right: 20,
            top: (pos == 'bottom' ? 'auto' : 20)
          }).fadeIn(200);
          
        }).click(function(e){
          helpers.close_overlay();
          e.preventDefault();
        });
      }
    });    
  },

  close_overlay: function(forsed) {

    $("body>#overlay").fadeOut(100,function(){
      $(this).detach();
      $("body>.bg").fadeOut(100,function(){
        $(this).detach();
      });      
    });    
  }

};


function setNavigation() {
  var path = window.location.pathname;
  var dir = path.split('/')[1]  
  
  $("header nav>ul>li>a").each(function () {
    var href = $(this).attr('href');
    if (dir == href.split('/')[1] ) {
      $(this).addClass('active');
    }
  });
}


function create_viewer_with_url(url) {
  let viewer = OpenSeadragon({
    id: "original-img",
    tileSources: {
      type: 'image',
      url:  url
    },
    showNavigationControl: false
  });
  return viewer;
}





// Open zoom
$(document).on('click', ".no-touchevents .case-grid img", function(e){   
  
  let $this = $(this),
      bg = $("<div id='zoom-bg'><div id='original-img'></div><button type='button' class='close'></button></div>").appendTo('body'),
      urls = $('.case-grid img').map(function(){ return $(this).data('url') }).get(),
      url = $this.data('url'),
      viewer = create_viewer_with_url(url);     
    
  $('body').css('overflow','hidden');  
  
  bg.data('viewer', viewer);
  bg.data('url', url);
  bg.data('urls', urls)  
  
  if (urls.length > 1) {
  
    $('<button type="button" rel="next"></button><button type="button" rel="prev"></button>').appendTo(bg).on('click', function(){
      let $this = $(this),
          current_i = urls.indexOf(bg.data('url')),
          i = 0;
  
      if ($this.attr('rel') == 'next') {
        i = (current_i < (urls.length - 1)) ? (current_i + 1) : 0;
      } else {
        i = (current_i > 0) ? (current_i - 1) : (urls.length - 1)
      }      
      
      viewer.open({
        type: 'image',
        url: urls[i]
      });
      
      bg.data('url', urls[i]);
      
    });      
  }
  
  
  // Close
  $('button.close', bg).on('click', function(){
    bg.remove();
    $('body').css('overflow','auto');
  });
  
  e.preventDefault();

});